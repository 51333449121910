<template>
  <div id="app" class="app">
    
     
      <router-view></router-view>
     
 

   
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
   
  },
  data() {
    return {
    
    }
  },
  watch: {
  
  },
}
</script>

<style lang="less">
.el-tabs__item.is-active {
    color: #004f6e !important;
}
.el-tabs__item:hover {
    color: #004f6e !important;
}
.el-tabs__active-bar {
    
    background-color: #004f6e !important;
 
}
html,body{
  font-family: PingFangSC, PingFang SC;
}
* {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  // color: #1D2129;
  font-weight: 400;

}
.el-main,
.el-header,
.el-footer {
  margin: 0 !important;
  padding: 0 !important;
  background-color: rgba(245, 247, 250, 1);
}
.anchorBL {
  display: none;
}
.el-footer {
  background: url('./assets/images/u213.png') 100% 100% no-repeat;
  background-size: cover;
  box-shadow: 0px 0 13px 0px #ccc;
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 200ms;
  position: absolute;
  /* position: relative; */
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
// .login-wrap {
//   .el-form {
//     .el-form-item__label {
//       color: #fff;
//     }
//     .el-input__inner {
//       background: rgba(255, 255, 255, 0.1);
//       border: none;
//       color: #fff;
//     }
//   }
// }
/* 屏幕小于1440px */
@media screen and (max-width: 1440px) {
  .width {
    padding: 0 10px;
  }
}

/* 屏幕等于1440px */
@media screen and (max-width: 1440px) and (min-width: 1440px) {
  .width {
    width: 90%;
    margin: 0 auto;
  }
}

/* 屏幕大于1440px */
@media screen and (min-width: 1441px) {
  .width {
    width: 1440px;
    margin: 0 auto;
  }
}
</style>
