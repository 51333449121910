<template>
  <div class="home">
    <div class="home_bar">
      <img src="../assets/images/img_logo.png" alt srcset />
      <div class="bar_item">
        <span class="active">
          <i class="el-icon-house"></i>首页
        </span>
        <span>
          <i class="el-icon-notebook-2"></i>通讯录
        </span>
      </div>
      <div class="bar_bottom">
        <router-link to="/setting/take">
          <el-link icon="el-icon-setting">企业空间设置</el-link>
        </router-link>

        <!-- <span>
          <i class="el-icon-setting"></i>企业空间设置
        </span>-->
      </div>
    </div>
    <div class="home_content">
      <div class="content_bar">
        <div class="content_soo">
          <el-input
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            v-model="input1"
            style="width: 240px;"
          ></el-input>
          <div class="soo_item">
            <span class="wxguan">关注小程序</span>
            <div class="soo_item_right">
              <i class="el-icon-bell aaa"></i>
              <i class="el-icon-menu aaa"></i>
              <el-dropdown trigger="click">
                <span class="el-dropdown-link" style="display: flex;align-items: center;">
                  <img src="../assets/logo.png" alt srcset style="margin-right: 5px;" />
                  <span>称意云</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-circle-plus">狮子头</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-circle-plus-outline">螺蛳粉</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-circle-check">蚵仔煎</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div class="bar_list">
          <el-card shadow="hover" v-for="(item,i) in menuList" :key="i">
            <div class="bar_list_item" @click="cartClick(item)">
              <div class="sids">
                <img :src="item.imgUrl" alt />
                <div class="t_text">
                  <span class="tt">{{item.zname}}</span>
                  <span class="cc">{{item.name}}</span>
                </div>
              </div>

              <i :class="item.icons"></i>
            </div>
          </el-card>
        </div>
      </div>
      <div class="content_bg">
        <div class="first_content">
          <div class="tasks">
            <div class="task_title">最近任务</div>
            <ul class="task_item">
              <li>
                <em></em>10月份样衣拍摄及渲染图制作
              </li>
              <li>
                <em></em>
                <span>外部</span>微信视频号直播嘉宾连线
              </li>
              <li>
                <em></em>一件衬衫的诞生，短视频制作
              </li>
              <li>
                <em></em>称意云衬衫基础单品秋冬上新
              </li>
            </ul>
          </div>
          <div class="answering">
            <div class="task_title">企业知识问答</div>
            <ul class="task_item">
              <li>
                <em></em>10月份样衣拍摄及渲染图制作
              </li>
              <li>
                <em></em>
                <span>外部</span>微信视频号直播嘉宾连线
              </li>
              <li>
                <em></em>一件衬衫的诞生，短视频制作
              </li>
              <li>
                <em></em>称意云衬衫基础单品秋冬上新
              </li>
            </ul>
          </div>
        </div>
        <div class="scent_content">
          <div class="tasks">
            <div class="task_title">外部协作空间</div>
            <ul class="task_item">
              <li>
                <em></em>10月份样衣拍摄及渲染图制作
              </li>
              <li>
                <em></em>
                <span>外部</span>微信视频号直播嘉宾连线
              </li>
              <li>
                <em></em>一件衬衫的诞生，短视频制作
              </li>
              <li>
                <em></em>称意云衬衫基础单品秋冬上新
              </li>
            </ul>
          </div>
          <div class="answering">
            <div class="task_title">扩展工具</div>
            <ul class="task_item">
              <li>
                <em></em>10月份样衣拍摄及渲染图制作
              </li>
              <li>
                <em></em>
                <span>外部</span>微信视频号直播嘉宾连线
              </li>
              <li>
                <em></em>一件衬衫的诞生，短视频制作
              </li>
              <li>
                <em></em>称意云衬衫基础单品秋冬上新
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      menuList: [
        {id:1,name:'在线协作',zname:'FLOW BOT',imgUrl:require('../assets/logo.png'),icons:'el-icon-arrow-right'},
        {id:2,name:'内容运营',zname:'DAM',imgUrl:require('../assets/logo.png'),icons:'el-icon-arrow-right'},
        {id:3,name:'商品运营',zname:'PRODUCT',imgUrl:require('../assets/logo.png'),icons:'el-icon-arrow-right'},
        {id:4,name:'客户运营',zname:'CRM',pathUrl:'/crm/index',imgUrl:require('../assets/logo.png'),icons:'el-icon-arrow-right'},
        {id:5,name:'飞雀智能',zname:'FAI',imgUrl:require('../assets/logo.png'),icons:'el-icon-arrow-right'},
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    cartClick(item) {
      console.log(123);
      this.$router.push(item.pathUrl)
    }
  }
};
</script>
<style>
.el-card__body {
  padding: 0;
}
</style>
<style lang="less" scoped>
.home {
  display: flex;
  .home_bar {
    position: relative;
    width: 236px;
    height: calc(100vh - 48px);
    padding: 24px;
    .bar_bottom {
      position: absolute;
      bottom: 10px;
      left: 44px;
      .el-link {
        font-size: 14px;
      }
    }
  }
}
.bar_item {
  margin-top: 24px;
  span {
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: 16px;
    border-radius: 4px;
    font-size: 14px;
    i {
      margin-right: 5px;
    }
  }
  .active {
    background: #004f6e;
    color: #fff;
    i {
      color: #ffffff;
    }
  }
}
.home_content {
  width: 100%;
}
.content_bar {
  background: #004f6e;
  height: 140px;
  border-radius: 0px 0px 4px 4px;
}
.content_soo {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  .soo_item {
    display: flex;
    align-items: center;
    width: 320px;
    .wxguan {
      width: 98px;
      height: 32px;
      border-radius: 2px;
      border: 1px solid #ffffff;
      font-size: 14px;
      color: #ffffff;
      line-height: 32px;
      text-align: center;
      margin-right: 27px;
    }
    .soo_item_right {
      display: flex;
      align-items: center;
      width: 220px;
      justify-content: space-between;
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #ffffff;
      }
    }
    .aaa {
      color: #fff;
      font-size: 26px;
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.bar_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  .bar_list_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 308px;
    height: 96px;
    background: #ffffff;
    border-radius: 10px;
    .sids {
      display: flex;
      align-items: center;
    }
    img {
      width: 48px;
      height: 48px;
      background: rgba(52, 145, 250, 0.12);
      border-radius: 10px;
      margin: 24px 16px 24px 24px;
    }
    .t_text {
      span {
        display: block;
        height: 24px;
        line-height: 24px;
      }
      .tt {
        font-size: 14px;
        color: #86909c;
      }
      .cc {
        font-size: 18px;
        color: #1d2129;
      }
    }
    i {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      line-height: 24px;
      background: #e5e6eb;
      text-align: center;
      color: #fff;
      margin-right: 24px;
    }
  }
}
.content_bg {
  background: #f7f8fa;
  height: calc(100vh - 160px);
}
.first_content {
  padding-top: 68px;
  margin: 0 24px;
  display: flex;
}
.scent_content {
  margin: 24px;
  display: flex;
}
.task_title {
  height: 57px;
  line-height: 57px;
  padding-left: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #1d2129;
  border-bottom: 1px solid #f0f2f5;
}
.tasks {
  width: 640px;
  height: 300px;
  background: #ffffff;
  border-radius: 4px;
  margin-right: 24px;

  .task_item {
    margin: 0;
    padding-left: 0;
  }
  .task_item li {
    display: flex;
    align-items: center;
    height: 54px;
    box-shadow: 0px 1px 0px 0px #f2f3f5;
    padding-left: 24px;

    em {
      width: 8px;
      height: 8px;
      background: #004f6e;
      border-radius: 50%;
      margin-right: 10px;
    }
    span {
      width: 32px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      background: #fff7e8;
      font-weight: 400;
      font-size: 10px;
      color: #ff7d00;
      margin-right: 10px;
    }
  }
}
.answering {
  width: 100%;
  background: #fff;
}
</style>
