import "babel-polyfill";
import * as echarts from 'echarts';
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import vRegion from 'v-region';
import Vue from 'vue';
import BaiduMap from 'vue-baidu-map';
import App from './App.vue';
import './assets/css/main.css';
import './assets/css/normalize.css';
import http from './http';
import https from './https';
import './plugins/element.js';
import router from './router';
import store from './store';
// 全局过滤器（时间戳）
import { formatDate, formatDate1, formatDate2, formatDate3, formatDate4 } from './utils/formatDate';
Vue.prototype.$echarts = echarts
Vue.prototype.$http = http
Vue.prototype.$https = https
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'yS3oXOBnsMvqfjIMhTjYw136j1WpYscl'
})
Vue.use(mavonEditor);
Vue.use(vRegion)
Vue.config.productionTip = false
Vue.filter('formatDate', function (time) {
  time = time * 1000
  let date = new Date(time)
  // console.log(new Date(time))
  return formatDate(date, 'yyyy-MM-dd hh:mm')
});
Vue.filter('formatDate1', function (time) {
  time = time * 1000
  let date = new Date(time)
  // console.log(new Date(time))
  return formatDate1(date, 'yyyy年MM月dd日 hh:mm')
});
Vue.filter('formatDate2', function (time) {
  time = time * 1000
  let date = new Date(time)
  // console.log(new Date(time))
  return formatDate2(date, 'MM月dd日 hh:mm')
});
Vue.filter('formatDate3', function (time) {
  time = time * 1000
  let date = new Date(time)
  // console.log(new Date(time))
  return formatDate3(date, 'yyyy年MM月dd日')
});
Vue.filter('formatDate4', function (time) {
  time = time * 1000
  let date = new Date(time)
  // console.log(new Date(time))
  return formatDate4(date, 'yyyy-MM-dd')
});
window.addEventListener('popstate', function (e) {
  router.isBack = true
}, false)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
