import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/home'
  }, {
    path: '/home',
    name: 'home',
    component: Home
  }, 
  {
    // 登录/注册
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }, 
  {
    // 客户CRM
    path: '/crm/index',
    name: 'crmIndex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/crm/Index.vue')
  },
  
  {
    // 登录/注册
    path: '/setting/index',
    name: 'settingIndex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/settingSpace/index.vue'),
    children: [
      {
        // 场景应用
        path: '/setting/take',
        name: 'settingTake',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/settingSpace/Take.vue'),
      },
      {
        // 成员管理
        path: '/setting/user',
        name: 'settingUser',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/settingSpace/UserContal.vue'),
      },
      {
        // 多渠道授权
        path: '/setting/empoewr',
        name: 'settingEmpoewr',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/settingSpace/Empoewr'),
      }
    ]
  }
  
]

const router = new VueRouter({
  routes,

})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
